// GENERIC CLASSES
%abstract-button {
  align-items: $ctr;
  border-radius: $m-base;
  cursor: pointer;
  display: $d-inflx;
  font-size: $font-size-sm;
  font-weight: $font-weight-light;
  height: 34px;
  justify-content: $ctr;
  margin: $null;

  svg {
    fill: inherit;
  }
}

%primary-button {
  @extend %abstract-button;

  border: $null;
  background-color: $color-dark-blue;
  color: $font-color-off-white;
}

%secondary-button {
  @extend %abstract-button;

  border: 1px solid $color-dark-blue;
  background-color: transparent;
  color: $color-dark-blue;
}

%off-button {
  @extend %abstract-button;

  background-color: $bg-color-light-gray;
  border: $null;
  color: $bg-color-dark-gray;
  pointer-events: none;
}

// EVENTS
%primary-button:hover {
  background-color: $bg-color-dark-gray;
  color: $color-dark-blue;
}
%primary-button:disabled {
  background-color: $bg-color-light-gray;
  color: $bg-color-dark-gray;
  cursor: default;
}
%secondary-button:hover {
  border: 1px solid $border-color;
  color: $border-color;
}
