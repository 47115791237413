@import 'App.scss';

.zone {
  &__container {
    background-color: rgb(227, 225, 241, 0.8);
    @media only screen and (max-width: 1200px) {
      border-radius: 4px;
      border: $bg-color-dark-gray 1px dashed;
    }
    @media only screen and (min-width: 1300px) {
      /* Large screen, non-retina */
      border-radius: 4px;
      border: $bg-color-dark-gray 1.5px dashed;
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1300px),
      only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 1300px),
      only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 1300px),
      only screen and (min-device-pixel-ratio: 2) and (min-width: 1300px),
      only screen and (min-resolution: 192dpi) and (min-width: 1300px),
      only screen and (min-resolution: 2dppx) and (min-width: 1300px) {
      border-radius: 4px;
      border: $bg-color-dark-gray 1.5px dashed;
    }

    z-index: $z-foreground;
    display: $d-flx;
    justify-content: $ctr;
    overflow: hidden;

    &-empty,
    &-factual-solution,
    &-card {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      width: 100%;
      .nb {
        flex: 1;
        align-items: center;
        display: flex;
        > div {
          margin: auto;
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          // @media only screen and (max-width: 1200px) {
          //   width: 80%;
          // }
          p {
            line-height: 0.8;
            @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 700px),
              only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 700px),
              only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 700px),
              only screen and (min-device-pixel-ratio: 2) and (min-width: 700px),
              only screen and (min-resolution: 192dpi) and (min-width: 700px),
              only screen and (min-resolution: 2dppx) and (min-width: 700px) {
              font-size: 4px;
              /* Medium screen, retina, stuff to override above media query */
            }
            @media only screen and (max-width: 1200px) {
              font-size: 3.5px;
            }
            &.factual {
              color: $zone-color-rose;
            }
            &.solution {
              color: $zone-color-blue;
            }
            &.thematique {
              color: $zone-color-yellow;
            }
            &.grey {
              color: $zone-color-grey;
            }

            span {
              margin-left: 10%;
              font-size: 12px;
              &.darkgrey {
                color: $zone-color-dark-grey;
              }
              @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 700px),
                only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 700px),
                only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 700px),
                only screen and (min-device-pixel-ratio: 2) and (max-width: 700px),
                only screen and (min-resolution: 192dpi) and (min-width: 700px),
                only screen and (min-resolution: 2dppx) and (min-width: 1200px) {
                font-size: 6px;
                /* Medium screen, retina, stuff to override above media query */
              }
              @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1300px),
                only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 1300px),
                only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 1300px),
                only screen and (min-device-pixel-ratio: 2) and (min-width: 1300px),
                only screen and (min-resolution: 192dpi) and (min-width: 1300px),
                only screen and (min-resolution: 2dppx) and (min-width: 1300px) {
                /* Large screen, retina, stuff to override above media query */
                font-size: 10px;
              }
              @media only screen and (max-width: 1200px) {
                font-size: 5px;
              }
              @media only screen and (min-width: 1201px) and (max-width: 1440px) {
                font-size: 10px;
              }
            }
          }
        }
      }
      .smallbox {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5px 2px;
        overflow: hidden;
        max-height: 37%;
        min-height: 37%;
        @media only screen and (max-width: 1200px) {
          margin: 1px 4.7px;
        }
        @media only screen and (min-width: 1201px) and (max-width: 1440px) {
          margin: 1px 6px;
        }
        @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1300px),
          only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 1300px),
          only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 1300px),
          only screen and (min-device-pixel-ratio: 2) and (min-width: 1300px),
          only screen and (min-resolution: 192dpi) and (min-width: 1300px),
          only screen and (min-resolution: 2dppx) and (min-width: 1300px) {
          /* Large screen, retina, stuff to override above media query */
          gap: 0.5px 2.5px;
          margin: 1px 4px;
        }
        margin: 0px 10px;
        margin-top: 1px;
      }
    }
    &-hover {
      background-color: rgba(45, 22, 100, 0.5);
      border-radius: 7px;
      display: $d-flx;
      height: $height-full;
      position: $pos-abs;
      width: $width-full;
    }

    &.card-hovered {
      @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1300px),
        only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 1300px),
        only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 1300px),
        only screen and (min-device-pixel-ratio: 2) and (min-width: 1300px),
        only screen and (min-resolution: 192dpi) and (min-width: 1300px),
        only screen and (min-resolution: 2dppx) and (min-width: 1300px) {
        /* Large screen, retina, stuff to override above media query */
        border-width: 2px;
      }
      border-width: 3px;
      border-style: dashed;
      stroke-dasharray: 18, 18;
      stroke-linecap: round;

      &.receive-solution {
        border-color: $highlight-border-blue;
      }

      &.receive-factual {
        border-color: $highlight-border-pink;
      }

      &.receive-thematique {
        border-color: $highlight-border-beige;
      }
    }

    &-close-button {
      align-self: $ctr;
      border-radius: 11px;
      border: $null;
      cursor: $c-pointer;
      display: $d-flx;
      height: 1rem;
      width: 1rem;
      margin: $null;
      margin-left: auto;
      margin-right: auto;

      svg {
        height: $height-full;
        scale: 1.75;
        width: $width-full;
      }
    }

    p {
      font-size: 6px;
      height: min-content;
      margin: $null;

      @include xl {
        font-size: $font-size-xxs;
      }
      @media only screen and (min-height: 600px) and (max-height: 1000px) {
        font-size: 6px;
      }
      @media only screen and (min-height: 1000px) {
        font-size: 10px;
      }
      @media only screen and (min-width: 1201px) and (max-width: 1440px) {
        font-size: 6px;
      }
      @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1300px),
        only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 1300px),
        only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 1300px),
        only screen and (min-device-pixel-ratio: 2) and (min-width: 1300px),
        only screen and (min-resolution: 192dpi) and (min-width: 1300px),
        only screen and (min-resolution: 2dppx) and (min-width: 1300px) {
        /* Large screen, retina, stuff to override above media query */
        font-size: 6px !important;
      }
    }

    &.border-solid {
      border-width: 1.5px;
      border-style: solid;
    }

    // Class when a zone has a card but is hovered
    &.card-hovered.border-solid {
      border-width: 3px;
      border-style: dashed;
      stroke-dasharray: 18, 18;
      stroke-linecap: round;
      @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1300px),
        only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 1300px),
        only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 1300px),
        only screen and (min-device-pixel-ratio: 2) and (min-width: 1300px),
        only screen and (min-resolution: 192dpi) and (min-width: 1300px),
        only screen and (min-resolution: 2dppx) and (min-width: 1300px) {
        /* Large screen, retina, stuff to override above media query */
        border-width: 2px;
      }

      &.factual {
        border-color: $highlight-border-pink;
      }

      &.solution {
        border-color: $highlight-border-blue;
      }

      &.thematique {
        border-color: $highlight-border-beige;
      }
    }

    &.factual {
      border-color: $font-color-light-pink;
    }

    &.solution {
      border-color: $font-color-blue;
    }

    &.thematique {
      border-color: $font-color-beige;
    }

    &.receive {
      border-style: dashed;
      border-width: 2px;
    }

    &.receive-solution {
      border-color: $font-color-blue;
    }

    &.receive-factual {
      border-color: $font-color-light-pink;
    }

    &.receive-thematique {
      border-color: $font-color-beige;
    }
  }

  &__small-box {
    @media only screen and (max-width: 1200px) {
      border-radius: 0.7px;
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1300px),
      only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 1300px),
      only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 1300px),
      only screen and (min-device-pixel-ratio: 2) and (min-width: 1300px),
      only screen and (min-resolution: 192dpi) and (min-width: 1300px),
      only screen and (min-resolution: 2dppx) and (min-width: 1300px) {
      /* Large screen, retina, stuff to override above media query */
      border-radius: 0.8px;
    }
    border-radius: 2px;
    cursor: $c-pointer;
    margin-top: 3px;
    @media only screen and (min-width: 1440px) {
      flex: 0 1 20%;
    }
  }

  &__pink {
    background-color: $zone-color-rose;
  }

  &__blue {
    background-color: $zone-color-blue;
  }

  &__yellow {
    background-color: $zone-color-yellow;
  }

  &__receive-solution {
    border: $font-color-blue 2px dashed;
  }

  &__receive-factual {
    border: $font-color-light-pink 2px dashed;
  }

  &__receive-thematique {
    border: $font-color-beige 2px dashed;
  }
}

.text {
  padding: 2px;
}

.text-image-grid {
  display: grid;
  grid-template-columns: $width-full;
  grid-template-rows: 8px 24px;
  text-align: $ctr;
  width: $width-full;

  &__title {
    color: $color-dark-blue;
    font-size: 5px;
    font-weight: $font-weight-bold;
    grid-column-start: 1;
    grid-row-end: 1;
    grid-row-start: 1;
  }

  &__image {
    grid-column-start: 1;
    grid-row-start: 2;
    grid-row-end: 2;

    img {
      object-fit: cover;
      height: $height-full;
      width: $width-full;
    }
  }
}

.image {
  width: $width-full;

  img {
    height: $height-full;
    object-fit: contain;
    width: $width-full;
  }
}

.border-solid {
  border: $font-color-beige 1.5px solid;
}

.border-solid-factual {
  border: $font-color-light-pink 1.5px solid;
}

.border-solid-solution {
  border: $font-color-blue 1.5px solid;
}

.setted {
  border: $bg-color-dark-gray 0.9px dashed;
}

.badge-zone {
  position: absolute;
  top: -10px;
  background: $color-dark-blue;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: $bg-color-pure-white;
  font-size: 12px;
  right: -10px;
  cursor: pointer;
}

.portal {
  position: absolute;
  background: rgba(227, 225, 241, 1);
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  @media only screen and (max-width: 1200px) {
    font-size: 5px;
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1300px),
    only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 1300px),
    only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 1300px),
    only screen and (min-device-pixel-ratio: 2) and (min-width: 1300px),
    only screen and (min-resolution: 192dpi) and (min-width: 1300px),
    only screen and (min-resolution: 2dppx) and (min-width: 1300px) {
    /* Large screen, retina, stuff to override above media query */
    font-size: 5px;
  }

  @media only screen and (min-width: 1201px) and (max-width: 1440px) {
    font-size: 9px;
  }

  @media only screen and (min-height: 600px) and (max-height: 1000px) {
    font-size: 9px;
  }

  &__factual {
    color: $zone-color-rose;
  }
  &__solution {
    color: $zone-color-blue;
  }
  &__thematique {
    color: $zone-color-yellow;
  }
}

.notification-zone {
  position: absolute;
  width: 105%;
  height: 110%;
  border-radius: 8px;
  top: -2px;
  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1300px),
    only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 1300px),
    only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 1300px),
    only screen and (min-device-pixel-ratio: 2) and (min-width: 1300px),
    only screen and (min-resolution: 192dpi) and (min-width: 1300px),
    only screen and (min-resolution: 2dppx) and (min-width: 1300px) {
    /* Large screen, retina, stuff to override above media query */
    width: 110%;
    height: 120%;
    border-radius: 4px;
  }

  @media only screen and (min-height: 600px) and (max-height: 1000px) {
    width: 115%;
    height: 125%;
    border-radius: 4px;
  }

  p {
    @media only screen and (max-width: 1200px) {
      font-size: 4px;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1440px) {
      font-size: 7px;
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1300px),
      only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 1300px),
      only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 1300px),
      only screen and (min-device-pixel-ratio: 2) and (min-width: 1300px),
      only screen and (min-resolution: 192dpi) and (min-width: 1300px),
      only screen and (min-resolution: 2dppx) and (min-width: 1300px) {
      /* Large screen, retina, stuff to override above media query */
      font-size: 4px;
    }
    @media only screen and (min-height: 600px) and (max-height: 1000px) {
      font-size: 6px;
    }
    @media only screen and (min-height: 1000px) {
      font-size: 10px;
    }
    line-height: 1.1;
    font-size: 9px;
    text-align: center;
    color: #fff;
  }
  img {
    @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1300px),
      only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 1300px),
      only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 1300px),
      only screen and (min-device-pixel-ratio: 2) and (min-width: 1300px),
      only screen and (min-resolution: 192dpi) and (min-width: 1300px),
      only screen and (min-resolution: 2dppx) and (min-width: 1300px) {
      /* Large screen, retina, stuff to override above media query */
      width: 5.5px;
      height: 5.5px;
    }
    @media only screen and (max-width: 1200px) {
      width: 5.5px;
      height: 5.5px;
    }
    width: 10px;
    height: 10px;
  }
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  @media only screen and (max-width: 1200px) {
    border-radius: 4px;
    width: 115%;
    height: 120%;
  }

  @media only screen and (min-width: 1201px) and (max-width: 1440px) {
    border-radius: 4px;
    width: 120%;
    height: 120%;
  }

  &.success {
    background-color: #149228;
  }
  &.error {
    background-color: #b01515;
  }
}

// @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 700px),
//   only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 700px),
//   only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 700px),
//   only screen and (min-device-pixel-ratio: 2) and (min-width: 700px),
//   only screen and (min-resolution: 192dpi) and (min-width: 700px),
//   only screen and (min-resolution: 2dppx) and (min-width: 700px) {
//   /* Medium screen, retina, stuff to override above media query */
// }

// @media only screen and (min-width: 1300px) {
//   /* Large screen, non-retina */
// }

// @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1300px),
//   only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 1300px),
//   only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 1300px),
//   only screen and (min-device-pixel-ratio: 2) and (min-width: 1300px),
//   only screen and (min-resolution: 192dpi) and (min-width: 1300px),
//   only screen and (min-resolution: 2dppx) and (min-width: 1300px) {
//   /* Large screen, retina, stuff to override above media query */
// }
