@import 'App.scss';

.container-lot {
  @extend %abstract-lot;

  &__manage-lot {
    height: 13.313rem;
  }

  &__image {
    height: 6.25rem;
    position: relative;
    top: 0;
    left: 0;
  }

  &__title {
    @extend %abstract-lot-title;
  }

  &__time,
  &__trophy {
    align-items: $ctr;
    display: $d-inflx;
    gap: $m-base;
    height: $rem-round;
    margin-left: $rem-round;
    margin-bottom: 10px;
    text-align: $lft;
    width: $width-full;

    p {
      font-weight: $font-weight-bold;
      margin: $null;
    }
  }

  &__theme__blue {
    color: $font-color-light-blue;
    overflow: hidden;
    border-top-left-radius: $rem-round;
    border-top-right-radius: $rem-round;

    span {
      color: $font-color-light-blue;
    }
  }

  &__theme__pink {
    color: $font-color-light-pink;
    overflow: hidden;
    border-top-left-radius: $rem-round;
    border-top-right-radius: $rem-round;

    span {
      color: $font-color-light-pink;
    }
  }

  &__theme__green {
    color: #9980d4;
    overflow: hidden;
    border-top-left-radius: $rem-round;
    border-top-right-radius: $rem-round;

    span {
      color: #9980d4;
    }
  }

  &__theme__yellow {
    color: $font-color-beige;
    border-top-left-radius: $rem-round;
    border-top-right-radius: $rem-round;
    overflow: hidden;

    span {
      color: $font-color-beige;
    }
  }

  &__button {
    display: $d-flx;
    justify-content: flex-end;
    width: $width-full;

    button {
      margin-right: $rem-round;
      margin-top: 6px;
    }
  }

  &__ordre-management {
    display: $d-flx;
    margin-left: $rem-round;

    img {
      margin-left: 5px;
    }
  }

  &__manage-buttons {
    display: $d-flx;
    justify-content: space-between;
    width: $width-full;

    button {
      margin-right: $rem-round;
      margin-top: 6px;
    }
  }
}

.off {
  pointer-events: none;
  opacity: 0.5;
}

.locked {
  background-color: $font-color-off-white;
  display: $d-flx;
  opacity: 0.7;

  img {
    margin: auto;
  }
}

.blank {
  opacity: 0;
  display: none;
}
