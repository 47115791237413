@import 'App.scss';

.home {
  text-align: $ctr;

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 2fr));
    gap: 25px;
    place-items: center;
    padding-left: $null;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 14px;
    margin-top: 14px;
  }
}
