@import 'App.scss';

.create-map-controls {
  &__container {
    align-items: $ctr;
    display: $d-flx;
    flex-direction: column;
    justify-content: $ctr;
    position: $pos-rel;
    top: 70px;

    p {
      font-size: $font-size-md;
    }

    .title {
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
    }
  }

  &__buttons {
    column-gap: 100px;
    display: $d-flx;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    position: $pos-rel;
    top: multiply($m-base, 2);
  }

  &__button {
    display: $d-flx;
    justify-content: $ctr;
    margin-top: multiply($m-base, 2);
  }

  &__no-card-border {
    width: $width-card;
    height: $height-card;
    border: 4px $font-color-beige dashed;
    border-radius: $border-radius-base;
  }

  &__no-card-border-factual {
    width: $width-card;
    height: $height-card;
    border: 4px $font-color-light-pink dashed;
    border-radius: $border-radius-base;
  }

  &__no-card-border-thematique {
    width: $width-card;
    height: $height-card;
    border: 4px $font-color-beige dashed;
    border-radius: $border-radius-base;
  }

  &__no-card-border-solution {
    width: $width-card;
    height: $height-card;
    border: 4px $font-color-blue dashed;
    border-radius: $border-radius-base;
  }

  &__progress-bar {
    margin-top: 8px;
    margin-bottom: -6px;
    width: 135px;
    height: 4px;
    background-color: $bg-color-gray;
    border-radius: 8px;
  }

  &__progress-bar-fill {
    height: 100%;
    background-color: $font-color-pink;
    border-radius: 8px;
  }
}

.admin_create_lot .card {
  width: 276px;
  margin: auto;
}
