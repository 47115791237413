@import 'App.scss';

.map-admin-controls {
  &__container {
    width: 100%;
    margin: auto;
    margin-top: 20px;

    p {
      font-size: 20px;
    }
  }

  button {
    margin: 0 1rem;
  }

  &__title {
    p {
      font-size: 30px;
      text-align: center;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
