@import 'App.scss';

.validation-container {
  background-color: $color-validation;

  @extend %abstract-notification;

  &__close {
    cursor: $c-pointer;
    margin-bottom: $m-base;
    margin-left: auto;
    margin-right: $m-base;
    margin-top: 9px;
  }

  &__label {
    @extend %abstract-label;
  }

  &__valid {
    margin-right: 15.25px;
  }
}

.error-container {
  background-color: $color-error;

  @extend %abstract-notification;

  &__close {
    cursor: $c-pointer;
    margin-bottom: $m-base;
    margin-left: auto;
    margin-right: $m-base;
    margin-top: 9px;
  }

  &__label {
    @extend %abstract-label;
  }

  &__warning {
    position: $pos-fix;
    right: 50vw;
    margin-right: 215px;
    top: 6px;
  }
}

.loading-container {
  background-color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  @extend %abstract-notification;

  &__label {
    @extend %abstract-label;
  }

  &__loader {
    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 2px solid $color-dark-blue; /* Blue */
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
