@import 'App.scss';

.card-form {
  &__module {
    @extend %abstract-card-module;
    border: $font-color-beige 4px solid;
    transition: width 0.25s, height 0.25s;
  }

  &__module--small {
    //transform: scale(0.2);
    width: 50px;
    height: 50px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 80%;
      height: 80%;
    }
    .text-image__title {
      display: none;
    }

    &__solution:after {
      background: $font-color-light-blue;
    }
    &__factuel:after {
      background: $font-color-light-pink;
    }
    &__thematique:after {
      background: $font-color-beige;
    }
  }

  &__content {
    @extend %abstract-card-content;
    padding: 2px;
    color: $color-dark-blue;
    div {
      height: 100%;
      width: 100%;
      overflow: auto;
      word-wrap: break-word;
    }

    img {
      width: $width-full;
      height: $height-full;
    }
  }

  &__hover {
    display: $d-flx;
    height: calc($height-full - 8px);
    left: 4px;
    position: $pos-abs;
    top: 4px;
    width: calc($width-full - 8px);
  }

  &__image {
    max-height: 100%;
  }

  &__thematique {
    border: $font-color-beige 4px solid;
  }

  &__factuel {
    border: $font-color-light-pink 4px solid;
  }

  &__solution {
    border: $font-color-light-blue 4px solid;
  }
}
