%abstract-card-container {
  align-items: $ctr;
  display: inherit;
  flex-direction: column;
}

%abstract-card-module {
  align-items: $ctr;
  background-color: $bg-color-white;
  border-radius: $border-radius-base;
  color: $color-dark-blue;
  display: inherit;
  flex-direction: column;
  font-size: $font-size-md;
  height: 250px;
  justify-content: $ctr;
  margin: $null;
  padding: 9px;
  width: 250px;
  overflow: hidden;
}

%abstract-card-content {
  align-items: $ctr;
  border: $font-color-beige-dark 1px dashed;
  display: $d-flx;
  height: $height-full;
  justify-content: $ctr;
  width: $width-full;
}
