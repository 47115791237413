@import 'App.scss';

.create-card-form {
  display: $d-flx;
  flex-direction: column;
  font-family: $f-hel;
  height: $height-full;
  justify-content: $ctr;

  &__title {
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    line-height: $line-height-base;
    margin: $null;
    margin-top: 30px;
    margin-bottom: 5px;
    text-align: $ctr;
  }

  &__cards {
    display: inherit;
    justify-content: $ctr;
    gap: 10px;
    margin-top: 44px;
  }

  &__card-container {
    @extend %abstract-card-container;

    input[type='checkbox'] {
      appearance: none;
      background-color: #fff;
      border-radius: 2px;
      border: $border-width-md $color-dark-blue;
      cursor: pointer;
      display: grid;
      height: 18px;
      margin-top: 1.25rem;
      place-content: $ctr;
      width: 18px;

      &:checked {
        background-color: $color-dark-blue;
      }

      &:checked:after {
        content: '';
        width: 0.65em;
        height: 0.65em;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        box-shadow: inset 1em 1em $bg-color-white;
        color: $bg-color-white;
      }
    }
  }

  &__card-module {
    @extend %abstract-card-module;
    border: $font-color-beige 4px solid;
    white-space: break-spaces;
    word-wrap: break-word;
    position: relative;
    &-create {
      color: $font-color-beige-dark;
    }
    &-solution {
      border: $font-color-blue 4px solid;
    }
    &-factuel {
      border: $font-color-light-pink 4px solid;
    }
    &-thematique {
      border: $font-color-beige 4px solid;
    }
  }

  &__card-module__image-upload,
  &__card-module__image,
  &__card-module__text,
  &__card-content {
    @extend %abstract-card-content;

    &-create {
      display: flex;
      justify-content: center;
    }

    &-not-empty {
      padding-bottom: 10px;
      color: $color-dark-blue;
      height: $height-full;
      width: $width-full;
      overflow: auto;
    }

    &-solution {
      border: $font-color-blue 1px dashed;
    }
    &-factuel {
      border: $font-color-light-pink 1px dashed;
    }
    &-thematique {
      border: $font-color-beige 1px dashed;
    }

    img {
      width: 95%;
      height: 95%;
      object-fit: contain;
    }
  }

  &__card-module__image-upload {
    width: auto;
  }

  &__card-module__text-not-empty {
    font-weight: $font-weight-bold;
    overflow: auto;
    text-align: $ctr;
    width: $width-full;
    height: 15px;
    padding-bottom: 20px;
    font-size: 13px;
  }

  &__card-module__text {
    border-bottom: $null;
    height: 25px;
  }

  &__buttons {
    @extend %abstract-base-flexbox-buttons;

    margin-top: auto;
  }
}
