@import 'App.scss';

.lot-form {
  display: $d-flx;
  flex-direction: column;
  gap: 17px;
  height: $height-full;

  &__file-inputs,
  &__text-inputs {
    display: inherit;
    gap: 20px;
  }

  &__input-label {
    font-weight: $font-weight-bold;
    margin: $null;
    margin-bottom: 7px;
    margin-left: $rem-base;
  }

  &__label {
    margin: $null;
  }

  &__buttons {
    @extend %abstract-base-flexbox-buttons;

    margin-top: auto;
  }
}

.lot-form__input-label:after {
  content: ' *';
  color: $required-field-color;
}
