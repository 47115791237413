@import 'App.scss';

.LoginPageContainer {
  white-space: pre-line;
  background-color: #f1f0f9;
  background-image: url('../../../src/assets/backgrounds/Nuage.svg');
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
}

.LoginContent {
  padding-top: 60px;
  padding-left: 80px;
  z-index: 100;
  position: relative;
  max-width: 369px;
}

.TextInto {
  margin-top: 30px;
}

h2 {
  margin-top: 50px;
  font-family: $f-hel;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  color: $color-dark-blue;
}

.MapImage {
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 20;
  width: 550px;
}

.CloudImage {
  position: fixed;
  width: 100vw;
  bottom: 0px;
  right: 0px;
  z-index: 10;
}

.davLogo {
  width: 110px;
  height: 26px;
  position: fixed;
  right: 16px;
  top: 14px;
}

.checkBoxDiv {
  position: relative;
  top: -15px;
}

.login__button {
  margin-top: 10px;
  margin-right: 20px;
}

.lbm__instruction {
  font-weight: $font-weight-bold;
}

.emailLabel {
  margin-top: 45px;
}

.input__container {
  flex-direction: column;
  display: $d-flx;

  button {
    align-self: flex-end;
    margin-right: 20px;
    margin-top: 5px;
  }

  &-validity:after {
    content: ' *';
    color: $required-field-color;
  }
}

.validation__icon {
  position: $pos-abs;
  right: 35px;
  top: 20px;
}

.error__message {
  font-family: $f-hel;
  font-style: normal;
  font-size: $font-size-xs;
  font-weight: $font-weight-light;
  line-height: 14px;
  color: $color-error;
  position: $pos-rel;
  top: -5px;
  margin-bottom: 0px;
}
