@import 'App.scss';

.lbm-logo {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 5;
}

.home-button {
  position: absolute;
  top: 50px;
  left: 15px;
  z-index: 100;
  width: 87px;
  height: 34px;
}

.gamemap {
  display: grid;
  grid-template-columns: auto 354px;
  &.admin-map{
    grid-template-columns: auto 460px;
  }

  &__container-left {
    background-color: $bg-color-map-gray;
    border-top-left-radius: $border-radius-base;
    border-bottom-left-radius: $border-radius-base;
    display: table-column;
    width: auto;
    height: fit-content;
    padding-top: 50px;

    &-admin {
      background-color: $bg-color-map-gray;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      height: auto;
      max-height: $height-full;
      max-width: $width-full;
      width: auto;
    }
  }

  &__lbm-logo {
    padding: 20px;
  }

  &__container-right {
    border-bottom-right-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    display: table-column;
    width: 354px;
    height: 100%;
    top: 0px;
    right: 0px;

    &-admin {
      width: 460px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
  }

  &__map-container {
    border-top-left-radius: $border-radius-base;
    border-bottom-left-radius: $border-radius-base;
    left: $null;
    position: $pos-rel;
    top: $null;
    height: fit-content;
  }

  &__background-image {
    height: auto;
    max-height: 85vh;
    max-width: 100%;
    width: auto;

    &-admin {
      height: auto;
      max-height: 85vh;
      max-width: 100%;
      width: auto;
    }
  }

  &__test {
    z-index: 100;
    position: absolute;
    top: $null;
    left: 50px;
    font-size: 100px;
  }

  &__tools {
    bottom: $null;
    bottom: $rem-round;
    display: $d-flx;
    flex-direction: column;
    gap: $gap-base;
    height: fit-content;
    position: $pos-abs;
    right: $rem-round;
    width: multiply($rem-round, 3);
    z-index: 995;
    align-items: center;

    svg {
      display: $d-flx;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__buttons {
    background-color: $color-dark-blue;
    border-radius: $rem-round;
    border: $null;
    color: $font-color-off-white;
    font-weight: $font-weight-bold;
    height: multiply($rem-round, 2);
    margin: $null;
    width: multiply($rem-round, 2);

    &:hover {
      background-color: $bg-color-dark-gray;
      color: $color-dark-blue;
    }
  }
}
