.manage-step {
  height: 100%;
  h1 {
    font-family: Helvetica, sans-serif;
    font-size: 22px;
    font-weight: 700;
    color: #2d1664;
    margin-top: 0;
    margin-bottom: 2rem;
  }
  .card-lot {
    margin: 2rem auto;
    width: 200px;
    border: 1px solid #ececec;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    &.selected {
        margin: 0;
    }
    img {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    p {
      margin-left: 0.5rem;
    }
  }
  .steps {
    display: flex;
    height: 85%;
    margin-top: 1rem;
    &__container {
      flex: 1;
      border: 1px solid #e5e5e5;
      padding: 3rem;
      .step{
        min-height: 200px;
      }
    }
    &__sidebar {
      width: 300px;
      height: 100%;
      overflow: scroll;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        .header{
            margin: 2rem auto;
            text-align: center;
        }
    }
  }
}
.btn-go-step{
    position: absolute;
    bottom: 2rem;
    right: 2rem;
}
