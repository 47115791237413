@import 'App.scss';

.hover-edition {
  align-content: $ctr;
  background-color: $bg-color-pure-white;
  border-radius: $border-radius-base;
  column-gap: 15px;
  cursor: default;
  display: $d-flx;
  flex-wrap: wrap;
  justify-content: $ctr;

  a {
    bottom: 6px;
    color: $color-dark-blue;
    font-size: $font-size-sm;
    font-weight: $font-weight-light;
    position: $pos-abs;
    user-select: none;
    -webkit-user-drag: none;
  }

  a:hover {
    color: $font-color-pink;
  }
}
