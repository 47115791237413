@import 'App.scss';

.sector-selection {
  display: $d-flx;
  flex-direction: column;
  justify-content: center;
  align-items: $ctr;

  &__title {
    margin-bottom: $null;
    margin-top: 30px;
  }

  &__subtitle {
    margin-bottom: 33px;
  }

  p {
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    text-align: $ctr;
  }

  &__buttons {
    display: inherit;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 27px;
    margin-bottom: 30px;
    margin-left: auto;
  }
}
