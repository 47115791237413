// CONTAINS SASS VARIABLES

// THEME
$primary-color: #ffffff; // Example color, not definitive
$secondary-color: #000000; // Example color, not definitive

// COLORS
$bg-color-white: #ffffff;
$bg-color-pure-white: #f6f7fa;
$bg-color-gray: #cbc8e1;
$bg-color-white-gray: #fafafa;
$bg-color-map-gray: #eef1f6;
$bg-color-light-gray: #e3e1f1;
$bg-color-dark-gray: #a09bc7;
$bg-color-lighter-gray: #dfe3ed;

$border-color: #655e94;
$required-field-color: #e62e2e;

$color-light-gray: #c3cad9;
$color-light-blue: #bce5ec;
$color-light-pink: #fedeed;
$color-light-beige: #ffead4;
$color-light-beige: #f9f4e7;
$color-light-green: #acdaac;
$color-dark-pink: #b62181;
$color-dark-blue: #2d1664;
$color-error: #b01515;
$color-validation: #149228;

$font-color-off-white: #f1f0f8;
$font-color-light-green: #6fa96e;
$font-color-pink: #e74aad;
$font-color-light-pink: #faafd1;
$font-color-light-blue: #77d0df;
$font-color-blue: #32b9cf;
$font-color-beige: #ffcc9a;
$font-color-beige-dark: #ef9b48;

$highlight-border-blue: #44a5c3;
$highlight-border-pink: #e74aad;
$highlight-border-beige: #ef9b48;

$trsp: transparent;

// BORDER
$border-width-xs: 1px solid;
$border-width-md: 2px solid;

// FAMILY
$f-hel: Helvetica, sans-serif;

// SIZE
$font-size-xxs: 8px;
$font-size-xs: 10px;
$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 18px;

// WEIGHT
$font-weight-light: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// LINE Height
$line-height-base: 23px;

// POSITION
$pos-abs: absolute;
$pos-fix: fixed;
$pos-rel: relative;
$pos-stc: static;
$pos-sty: sticky;

// CURSOR
$c-pointer: pointer;

// DECORATION
$deco-under: underline;

// DISPLAY
$d-blk: block;
$d-inblk: inline-block;
$d-flx: flex;
$d-inflx: inline-flex;

// MARGIN
$m-base: 0.625rem;

// RADIUS
$border-radius-base: 8px;

// CONTENT ALIGNEMENT
$ctr: center;
$lft: left;
$rht: right;
$bot: bottom;

// DIMENSIONS
$width-card: 250px;
$height-card: 250px;
$width-full: 100%;
$width-half: 50%;
$height-full: 100%;
$height-half: 50%;

$rem-base: 0.5rem;
$rem-round: 1rem;

// FLEX
$f-wrap: wrap;
$gap-base: 10px;

// VALUE
$null: 0;

// Z-INDEX
$z-foreground-max: 999;
$z-foreground: 10;
$-z-background: 1;

// CSS ROOT VARIABLES
$tutorial-animation-width: var(--tutorial-animation-width);

// ZONE COLORS

$zone-color-rose: #E74AAD;
$zone-color-blue: #32B9CF;
$zone-color-yellow: #EF9B48;
$zone-color-grey: #A09BC7;
$zone-color-dark-grey: #655E94;
