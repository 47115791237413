@import 'App.scss';

.badges {
  text-align: $ctr;
  margin: auto;
  width: 50%;
  img {
    max-width: 129px;
    max-height: 129px;
  }

  &__grid {
    display: $d-flx;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;

    div {
      flex: 0 1 calc(25% - 15px);
      margin: 5px;
    }
  }

  &__grid_start {
    cursor: $c-pointer;
    display: $d-flx;
    justify-content: space-around;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    div {
      margin: 15px;
    }
  }

  &__img--unfinished {
    opacity: 0.2;
  }

  &__img--disabled {
    opacity: 0.2;
  }
}
