@import 'App.scss';

.zone-edit-popup {
  // Hide scrollbar for Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  p {
    text-align: center;
    font-size: 18px;
  }

  &__close-icon-container {
    cursor: $c-pointer;
    position: $pos-abs;
    right: 10px;
    top: 12px;
  }

  &__card-wrapper {
    border-radius: 7px;
    height: fit-content;
    position: $pos-rel;
    width: fit-content;
  }
  &__text-info-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    label {
      color: $color-dark-blue;
      font-weight: 700;
      padding-left: 10px;
      margin-bottom: 10px;
    }
    .buttons {
      margin-left: auto;
      margin-top: 15px;
      gap: 10px;
      display: flex;
    }
  }
  hr {
    margin-top: 20px;
  }
  .delete {
    text-align: center;
    margin-top: 20px;
    a {
        color: $color-dark-blue;
    }
    img {
        vertical-align: middle;
        margin-left: 10px;
        width: 12px;
    }
  }
}
