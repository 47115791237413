@import 'breakpoints';

// CONTAIN SASS MIXINS

// SCREEN MIXINS
@mixin xs {
  @media (min-width: map-get($breakpoints, 'xs')) {
    @content;
  }
}
@mixin sm {
  @media (min-width: map-get($breakpoints, 'sm')) {
    @content;
  }
}
@mixin md {
  @media (min-width: map-get($breakpoints, 'md')) {
    @content;
  }
}
@mixin lg {
  @media (min-width: map-get($breakpoints, 'lg')) {
    @content;
  }
}
@mixin xl {
  @media (min-width: map-get($breakpoints, 'xl')) {
    @content;
  }
}
@mixin xxl {
  @media (min-width: map-get($breakpoints, 'xxl')) {
    @content;
  }
}

// MARGIN - PADDING MIXINS

// ANIMATION SCSS
@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}
