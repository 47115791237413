@import 'App.scss';

.manage-lots {
  text-align: $ctr;

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(255px, 2fr));
    gap: 25px;
    place-items: center;
    padding-left: $null;
  }
}

.save-button {
  position: $pos-abs;
  right: 110px;
}
