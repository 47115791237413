@import 'App.scss';

.zone-popup {
  // Hide scrollbar for Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  &__grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    margin: 35px 30px;
    max-height: 365px;
    overflow-y: scroll;
    justify-items: center;
  }

  &__close-icon-container {
    cursor: $c-pointer;
    position: $pos-abs;
    right: 10px;
    top: 12px;
  }

  &__card-wrapper {
    border-radius: 7px;
    height: fit-content;
    position: $pos-rel;
    width: fit-content;
  }
  &__text-info{
    padding-top: 30px;
    p{
      text-align: center;
      max-width: 90%;
      margin: auto;
    }
  }
}
