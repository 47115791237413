@import 'App.scss';

.user-information {
  display: inherit;
  flex-direction: column;

  &__firstname,
  &__lastname {
    font-weight: $font-weight-bold;

    &:after {
      content: ' *';
      color: $required-field-color;
    }
  }

  &__warning {
    font-weight: $font-weight-bold;
    margin: $null;
    margin-top: 16px;
    text-align: $ctr;
  }

  button {
    align-self: flex-end;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
