@import 'App.scss';

.file-input-container,
.text-input-container {
  font-family: $f-hel;
  position: $pos-rel;

  &__elements {
    position: inherit;
  }

  &__icons {
    position: $pos-abs;
    right: 17px;
    top: 19px;
    height: 20px;

    &-login {
      position: $pos-abs;
      right: 30px;
      top: 19px;
      height: 20px;
    }
  }

  &__icons-edit {
    position: $pos-abs;
    right: 42px;
    top: 19px;
    height: 20px;
  }

  &__format-message &__error-message {
    font-family: inherit;
    font-style: normal;
    font-weight: $font-weight-light;
    line-height: 14px;
    position: $pos-rel;
  }

  &__error-message {
    color: $color-error;
    font-size: $font-size-xs;
    margin-bottom: $null;
    padding-left: $rem-round;
  }

  &__format-message {
    font-size: $font-size-sm;
    line-height: $line-height-base;
    margin: $null;
    margin-left: $rem-round;
  }

  &__file-icon {
    color: $color-dark-blue;
    position: $pos-abs;
    left: $width-half;
    top: 1.5rem;
    margin-left: -2.5rem;
  }

  &__filename {
    display: $d-flx;
    margin: $null;
    margin-top: 7px;
    margin-left: 1rem;
    margin-bottom: 4px;
    gap: $rem-base;

    p {
      font-size: $font-size-sm;
      margin: 0px;
    }
  }

  &__edit-icon {
    position: relative;
    top: 10px;
    left: 8px;
  }

  &__edit-icon:hover {
    cursor: pointer;
    border-radius: 50%;
    background-color: $bg-color-light-gray;
  }

  input:disabled + &__edit-icon {
    opacity: 0.5;
  }

  input:disabled + &__edit-icon:hover {
    cursor: default;
    background-color: transparent;
  }

  input:disabled {
    border-color: $bg-color-dark-gray;
    color: $bg-color-dark-gray;
  }

  input:disabled::-webkit-input-placeholder {
    color: $bg-color-dark-gray;
  }
}

.file-input-container {
  color: transparent;
  display: $d-flx;
  flex-direction: column;
  width: 365px;

  &__icons {
    position: $pos-abs;
    right: 30px;
    top: 22px;
    height: 20px;
  }

  input {
    cursor: pointer;
    width: $width-full;
  }

  input::after {
    content: 'Ajouter une image';
    margin-bottom: $null;
  }
}

.edit-file:nth-child(2) {
  margin-left: 30px;
}
