@import 'App.scss';

.introGame {
  background-color: $bg-color-map-gray;

  &__next-button {
    position: absolute;
    bottom: 23px;
    right: 28px;
    z-index: 100;
    width: 87px;
    height: 34px;
  }

  &__previous-button {
    position: absolute;
    bottom: 23px;
    right: 130px;
    z-index: 100;
    width: 87px;
    height: 34px;
  }

  &__video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }
}
