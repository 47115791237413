%abstract-popup {
  border-radius: 8px;
  top: 50%;
  box-shadow: 0 4px 16px 0px rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 400;
  left: 50%;
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 999;
}

%abstract-warning {
  font-size: 14px;
  font-weight: 700;
  padding-left: 1rem;
  padding-right: 1rem;
}
