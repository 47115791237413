@import 'App.scss';

.sector-duplication {
  align-items: $ctr;
  display: $d-flx;
  flex-direction: column;
  justify-content: flex-start;

  &__title {
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    margin-bottom: 30px;
    margin-top: 40px;
  }

  &__buttons {
    align-self: flex-end;
    column-gap: 15px;
    display: inherit;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
