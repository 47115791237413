@import 'App.scss';

.main__header-admin,
.main__header-dashboard {
  align-items: $ctr;
  display: $d-flx;
  height: 56px;
  justify-content: $ctr;
}

.main__header {
  &-admin {
    background-color: $font-color-off-white;
  }

  &-dashboard {
    background-color: $font-color-off-white;
  }
}

.main__header > * {
  margin: 0 2rem;
}

.main__container-admin,
.main__container-dashboard {
  display: $d-flx;
  flex-direction: column;
  min-height: 100vh;
}

.main__container {
  &-admin {
    background-color: $font-color-off-white;
  }

  &-dashboard {
    background-color: $font-color-off-white;
  }
}

.game .main__container{
  &-dashboard {
    background-color: #f6f7fa;
  }
}

.main__footer {
  align-items: $ctr;
  background-color: #140d49;
  column-gap: 46px;
  display: $d-flx;
  height: 61px;
  justify-content: $ctr;
  margin-top: auto;
  width: $width-full;

  &-link {
    align-items: $ctr;
    display: $d-flx;
    height: $height-full;
    flex-direction: column;

    a {
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
      color: $bg-color-white-gray;
      font-size: $font-size-md;
      font-weight: 500;
      margin-bottom: auto;
      margin-top: auto;
      text-decoration: none;
    }

    &:hover {
      div {
        width: $width-full;
        height: 6px;
        position: $pos-rel;
        background-color: rgba(231, 74, 173, 0.5);
      }

      a {
        padding-bottom: 5px;
      }
    }
  }
}

p {
  font-family: $f-hel;
  font-weight: $font-weight-light;
  font-size: $font-size-md;
  line-height: $rem-round;
  color: $color-dark-blue;
}

li {
  font-family: $f-hel;
  font-style: normal;
  font-weight: $font-weight-light;
  font-size: $font-size-md;
  line-height: $rem-round;
  color: $color-dark-blue;
  margin-left: $m-base;
}

button {
  margin: $rem-round;
}

.requiredInputLabel {
  font-weight: $font-weight-bold;

  &:after {
    content: ' *';
    color: $required-field-color;
  }
}

.relativeDiv {
  position: $pos-rel;
}

input[type='text'] {
  width: 326px;
  height: 52px;
  border: 1px solid $border-color;
  border-radius: 4px;
  padding-left: 16px;
  font-family: $f-hel;
  font-style: normal;
  outline: none;
  font-weight: $font-weight-light;
  font-size: $font-size-md;
  color: $color-dark-blue;

  &.inputError {
    border: 1px solid $color-error;
  }

  &.inputValid {
    border: 1px solid $color-validation;
  }

  &.noInput {
    border: 1px solid $border-color;
  }

  &::placeholder {
    font-family: $f-hel;
    font-weight: $font-weight-light;
    font-size: $font-size-md;
    line-height: 18px;
    color: $color-dark-blue;
  }
}

input:-webkit-autofill {
  -webkit-background-clip: text;
}

input[type='checkbox'] {
  margin-left: 0px;
}

input[type='checkbox']:checked {
  accent-color: $color-dark-blue;
}

.checkBoxLabel {
  font-size: 10px;
  padding-left: 8px;
}

.errorMessage {
  font-family: $f-hel;
  font-style: normal;
  font-size: $font-size-xs;
  font-weight: $font-weight-light;
  line-height: 14px;
  color: $color-error;
  position: relative;
  top: -17px;
  margin-bottom: 0px;
}

.validationIcon {
  position: $pos-abs;
  right: 17px;
  top: 22px;
}

.errorIcon {
  position: $pos-abs;
  right: 17px;
  top: 19px;
}

.GoButton {
  padding: 10px 24px;
  min-width: 71px;
  height: 34px;
  background: $color-dark-blue;
  border-radius: 10px;
  border: none;
  color: $font-color-off-white;
  margin: 0;

  &:disabled {
    background: $bg-color-light-gray;
    color: $bg-color-dark-gray;
  }

  &:hover {
    background: $bg-color-dark-gray;
    color: $color-dark-blue;
  }
}

.FlexEndDiv {
  display: flex;
  justify-content: flex-end;
}

.FlexDiv {
  display: flex;
}

input[type='file']:after {
  margin-top: -18px;
  width: 326px;
  height: 56px;
  border-radius: 4px;
  padding-left: 20px;
  margin-bottom: 17px;
  font-family: $f-hel;
  font-style: normal;
  outline: none;
  font-weight: $font-weight-light;
  font-size: $font-size-md;
  color: $color-dark-blue;
  background-color: $color-light-gray;
  //put text in input at center vertically
  display: flex;
  align-items: center;
}

input[type='file']::-webkit-file-upload-button {
  visibility: hidden;
}
