@import 'App.scss';
.sector {
  height: calc(100% - 5rem);
  display: flex;
  flex-direction: column;
  .sector-form__input-label {
    margin-top: 0;
  }
  &__form {
    > div {
      display: flex;
      flex-direction: column;
    }
    display: flex;

    .delete {
      margin-top: 2.3rem;
      margin-left: 15px;
      width: 30px;
      height: 30px;
      &:hover {
        background-color: $bg-color-light-gray;
        border-radius: 50px;
      }
      img {
        width: 16px;
        margin: auto;
        &:hover {
          cursor: $c-pointer;
        }
      }
    }
    &__buttons {
      display: flex;
      margin-top: 10px;
      margin-left: 10px;
      gap: 20px;
      a {
        color: $color-dark-blue;
        font-weight: $font-weight-light;
        font-size: $font-size-sm;
        text-decoration: $deco-under;
        font-family: Helvetica, sans-serif;
        &.disabled {
          color: $bg-color-dark-gray;
        }
        &:hover {
          cursor: $c-pointer;
          color: $font-color-pink;
        }
      }
    }
  }
}

.off {
  pointer-events: none;
  opacity: 0.5;
  user-select: none;
}
