@import 'App.scss';

.badge {
  &__container {
    background-color: #f1f0f9;
    //background-image: url('../../../../assets/backgrounds/Nuage.svg');
    background-attachment: fixed;
    background-size: cover;
    min-height: 90vh;
  }

  &__logo {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 100;
  }

  &__davidson-logo {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 100;
  }

  &__image {
    width: 130px;
    height: 130px;
    margin-top: 20px;
  }

  &__text {
    font-family: 'Helvetica';
    font-size: 18px;
    font-weight: 400;
    margin-top: 0px;
  }

  &__content-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-family: 'Helvetica';
    font-size: 24px;
    font-weight: 700;

    &__theme__blue {
      color: $font-color-light-blue;
    }

    &__theme__pink {
      color: $font-color-light-pink;
    }

    &__theme__yellow {
      color: $font-color-beige;
    }
  }

  &__buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    button {
      margin-right: 10px;
    }

    button:last-child {
      margin-right: 25px;
      width: 71px;
      height: 34px;
    }
  }
}
