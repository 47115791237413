@import 'App.scss';

.edit-card-form {
  display: $d-flx;
  flex-direction: column;
  font-family: $f-hel;
  gap: 1rem;
  height: $height-full;
  justify-content: $ctr;

  &__lot-title {
    display: $d-flx;
    color: $color-dark-blue;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    margin: $null;
    position: $pos-abs;
    right: 5%;
  }

  &__layout {
    max-height: 63vh;
    overflow: scroll;
    position: $pos-rel;

    // Hide scrollbar for Chrome, Safari and Opera
    &::-webkit-scrollbar {
      display: none;
    }

    // Hide scrollbar for IE, Edge and Firefox
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  // HACK: Used !important to override RGL grid element width & height
  &__card {
    cursor: default;
    height: fit-content !important;
    user-select: none;
    width: fit-content !important;

    // HACK: align grid to center (flexbox doesn't work well because of the transform rule applied to div when dragged)
    left: $null !important;
    right: $null;
    margin-left: auto;
    margin-right: auto;
  }

  &__no-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;

    p {
      font-family: $f-hel;
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
    }
  }

  &__buttons {
    display: $d-flx;
    margin-top: auto;
    @extend %abstract-base-flexbox-buttons;
  }
}
