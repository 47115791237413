@import 'App.scss';

.menu__container {
  background-color: $bg-color-white-gray;
  border-radius: $border-radius-base;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.2);
  display: $d-flx;
  flex-direction: column;
  margin-top: 180px;
  position: $pos-abs;
  z-index: $z-foreground;

  &__label-selected {
    background-color: $bg-color-light-gray;
  }

  &__label-pink {
    color: $font-color-pink;
  }

  p {
    border-bottom: 1px $bg-color-light-gray solid;
    cursor: pointer;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    margin: $null;
    padding: 9px;

    &:hover {
      background-color: $bg-color-light-gray;
    }
  }

  p:first-child {
    border-top-left-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
  }

  p:last-child {
    border-bottom-left-radius: $border-radius-base;
    border-bottom-right-radius: $border-radius-base;
  }
}
