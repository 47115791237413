@import 'App.scss';

.lot-form {
  display: $d-flx;
  flex-direction: column;
  gap: 17px;
  height: $height-full;

  &__file-inputs {
    column-gap: $null;
    display: inherit;
    row-gap: 20px;
  }

  &__text-inputs {
    display: inherit;
    gap: 20px;
  }

  &__input-label {
    font-weight: $font-weight-bold;
    margin: $null;
    margin-bottom: 7px;
    margin-left: $rem-base;
  }

  &__label {
    margin: $null;
  }

  &__buttons {
    @extend %abstract-base-flexbox-buttons;

    margin-top: auto;
  }

  // Add the custom radio button styles here
  &__radio-group {
    display: flex;
    gap: 1rem;

    label {
      font-family: $f-hel;
      font-weight: $font-weight-light;
      font-size: $font-size-md;
      line-height: 18px;
      color: $color-dark-blue;
    }

    .custom-radio {
      display: flex;
      align-items: center;

      input[type='radio'] {
        display: none;

        & + .radio {
          width: 20px;
          height: 20px;
          border: 2px solid $color-dark-blue;
          border-radius: 50%;
          position: relative;
          cursor: pointer;
          margin-right: 5px;
        }

        &:checked + .radio {
          border-color: $color-dark-pink;
        }

        &:checked + .radio::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 10px;
          height: 10px;
          background-color: $color-dark-pink;
          border-radius: 50%;
          transform: translate(-50%, -50%);
        }

        // Styles when checked and disabled
        &:checked:disabled + .radio {
          border-color: $color-dark-pink;
          cursor: not-allowed;
        }

        // Style the custom radio button when disabled
        &:disabled + .radio {
          border-color: $bg-color-dark-gray;
          cursor: not-allowed;
        }

        // Add a disabled style for the label text
        &:disabled ~ span.label-text {
          color: $bg-color-dark-gray;
        }
      }

      span.label-text {
        margin-left: 0.5rem;
      }

      // Style the custom radio button when disabled
      input[type='radio']:disabled + .radio {
        border-color: $bg-color-dark-gray;
        cursor: not-allowed;
      }

      // Add a disabled style for the label text
      input[type='radio']:disabled ~ span.label-text {
        color: $bg-color-dark-gray;
      }
    }
  }
}
