@import 'App.scss';
.detail-steps {
  height: 100%;
  .hovered {
    .placeholder {
      border: 2px solid $bg-color-dark-gray;
    }
  }
  h1 {
    font-family: Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #2d1664;
    margin-top: 0;
    margin-bottom: 0;
  }
  .card-lot {
    width: 176px;
    height: 100px;
    border: 1px solid #ececec;
    background: $font-color-off-white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    position: relative;

    &.inStep{
      .delete-container{
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img{
          width: 22px;
          height: 22px;
        }
      }
      &.canDelete:hover:before{
        background: $color-dark-blue;
        opacity: 0.5;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        border-radius: 10px;
      }
    }
    


    &.list{
      margin: 1rem auto;
    }
    &.selected {
      margin: 0;
    }
    &.placeholder {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: none;
      background-color: $bg-color-light-gray;
      color: $bg-color-dark-gray;
      width: 176px;
      height: 100px;

      p {
        margin: 0 0 0.5rem 0;
        color: $bg-color-dark-gray;
        font-size: 12px;
        font-weight: 400;
      }
      img {
        width: 40px;
      }
    }

    img {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      pointer-events: none;
      cursor: default;
      height: 62px;
    }
    p {
      margin-left: 0.5rem;
      color: $color-dark-blue;
      font-weight: 700;
      margin-top: 8px;
      font-size: 10px;
    }
  }
  .steps {
    display: flex;
    height: 100%;

    &__container {
      width: calc(100% - 225px);
    }

    &__sidebar {
      width: 225px;
      height: 100%;
      background: $font-color-off-white;
      position: absolute;
      right: 0;
      top: 0;
      overflow: scroll;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      .header {
        margin: 2rem 10px 1rem 10px; 
        text-align: center;
      }
    }
  }
}

.addStep{
  p{
      color: $color-dark-blue;
      font-weight: $font-weight-light;
      font-size: $font-size-sm;
      text-decoration: $deco-under;
      font-family: Helvetica, sans-serif;
      margin-top: 0;
      &:hover {
        cursor: $c-pointer;
        color: $font-color-pink;
      }
    }
}

.btn-container{
  position: absolute;
  right: 250px;
  bottom: 2rem;
  display: flex;
  gap: 1rem;
}
.sector-label{
  position: absolute;
  right: 250px;
  top: 2.3rem;
  font-size: 18px;
  font-weight: 700;
  color: $color-dark-blue;
}