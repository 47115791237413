@import 'App.scss';

.container {
  align-items: $ctr;
  color: $color-dark-blue;
  display: $d-inflx;
  flex-wrap: $f-wrap;
  font-family: $f-hel; // Maybe include the font in the body
  height: multiply($rem-base, 5);
  width: $width-full;
  margin: 0;

  &__lbm-logo {
    margin-left: multiply($rem-base, 2);
    margin-right: 73px;

    @media (max-width: 1025px) {
      margin-right: 45px;
    }

    @include xs {
      width: multiply($rem-base, 30);
    }

    @include lg {
      width: multiply($rem-base, 37);
    }
  }

  &__davidson-logo {
    margin-left: auto;
    margin-right: multiply($rem-base, 2);

    @include xs {
      width: multiply($rem-base, 16);
    }

    @include lg {
      width: 100px;
      height: 22px;
    }
  }

  &__link {
    color: $color-dark-blue;
    font-size: $font-size-md;
    font-weight: $font-weight-light;
    margin-right: 1.875rem;
    text-decoration: none;

    @media (max-width: 1025px) {
      margin-right: calc(1.875rem - 7px);
    }

    @include xs {
      font-size: $font-size-sm;
    }

    @include lg {
      font-size: $font-size-md;
    }
  }

  &__link:hover {
    color: $font-color-pink;
  }

  &__link-selected {
    text-decoration: underline;
    color: $font-color-pink;
  }
}

.bg-color-admin {
  background-color: $font-color-off-white;
}

.bg-color-dashboard {
  background-color: $font-color-off-white;
}
