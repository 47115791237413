@import 'App.scss';

.card-popup {
  &__image-uploaded {
    z-index: $z-foreground;
  }

  &__textarea-image {
    &__image-uploaded {
      z-index: $z-foreground;
    }
  }
}
