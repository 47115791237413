@import 'App.scss';

.admin-container {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: $bg-color-pure-white;
  border-radius: 10px;
  display: $d-flx;
  flex-direction: column;
  gap: multiply($rem-round, 2);
  max-width: 92%;
  height: 85vh;
  margin: 16px;
  margin-top: 0px;
  padding: 30px;
  position: relative;
  &.admin-steps{
    height: auto;
    min-height: 85vh;
  }
}

.manage-lot-container {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: $bg-color-pure-white;
  border-radius: 10px;
  display: $d-flx;
  flex-direction: column;
  gap: multiply($rem-round, 2);
  max-width: 92%;
  margin: 16px;
  margin-top: 0px;
  padding: 30px;
}

.admin-map-container {
  background-color: bg-color-pure-white;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: $d-flx;
  flex-direction: column;
  gap: multiply($rem-round, 2);
  margin: 15px;
  margin-top: $null;
  max-width: 100%;
  max-height: 85vh;
  position: $pos-rel;
}

.title-container {
  display: $d-flx;
  font-family: $f-hel;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  color: $color-dark-blue;
  margin: $null;
  align-items: $ctr;

  img {
    padding: 8px 3px;
    position: relative;
    top: 7px;
    left: 7px;
  }

  img:hover {
    background-color: $color-light-gray;
    border-radius: 50%;
    cursor: pointer;
  }

  &__title {
    cursor: pointer;

    &-pink {
      color: $font-color-pink;
      white-space: pre;
    }
  }
}

.menu-to-right {
  &-creation {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    position: $pos-abs;
    right: 55px;
  }

  &-edition {
    margin-left: auto;
    margin-right: auto;
    margin-top: 65px;
    position: $pos-abs;
    right: 55px;
  }
}
