@import 'App.scss';

.ml-icon {
  margin-left: 0.688rem;
}

.mr-icon {
  margin-right: 0.563rem;
}

.primary-button-xs {
  @extend %primary-button;
}

.secondary-button-xs {
  @extend %secondary-button;
}

.off-button-xs {
  @extend %off-button;
}

.primary-button-xs,
.secondary-button-xs,
.off-button-xs {
  width: setButtonWidth('xs');
}

.primary-button-s {
  @extend %primary-button;
}

.secondary-button-s {
  @extend %secondary-button;
}

.off-button-s {
  @extend %off-button;
}

.primary-button-s,
.secondary-button-s,
.off-button-s {
  width: setButtonWidth('s');
}

.primary-button-md {
  @extend %primary-button;
}

.secondary-button-md {
  @extend %secondary-button;
}

.off-button-md {
  @extend %off-button;
}

.primary-button-md,
.secondary-button-md,
.off-button {
  width: setButtonWidth('md');
}

.primary-button-modify {
  @extend %primary-button;
}

.secondary-button-modify {
  @extend %secondary-button;
}

.off-button-modify {
  @extend %off-button;
}

.primary-button-modify,
.secondary-button-modify,
.off-button-modify {
  width: setButtonWidth('modify');
}

.primary-button-lg {
  @extend %primary-button;
}

.secondary-button-lg {
  @extend %secondary-button;
}

.off-button-lg {
  @extend %off-button;
}

.primary-button-lg,
.secondary-button-lg,
.off-button-lg {
  width: setButtonWidth('lg');
  &.game {
    width: setButtonWidth('xl') + 30px;
  }
}

.primary-button-xl {
  @extend %primary-button;
}

.secondary-button-xl {
  @extend %secondary-button;
}

.off-button-xl {
  @extend %off-button;
}

.primary-button-xl,
.secondary-button-xl,
.off-button-xl {
  width: setButtonWidth('xl');
}

.primary-button-add-card {
  @extend %primary-button;
}

.secondary-button-add-card {
  @extend %secondary-button;
}

.off-button-add-card {
  @extend %off-button;
}

.primary-button-add-card,
.secondary-button-add-card,
.off-button-add-card {
  width: setButtonWidth('add-card');
}
