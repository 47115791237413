%abstract-notification {
  display: flex;
  font-family: $f-hel;
  height: 33px;
  position: $pos-fix;
  text-align: center;
  top: $null;
  width: 100%;
  z-index: 10;
}

%abstract-label {
  color: #fafafa;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: auto;
  margin-left: auto;
  margin-top: auto;
}
